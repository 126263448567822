<template>
  <div>
    <h2>Eventos</h2>
    {{events}}
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  layout: "entrada",
  data() {
    return {
      events: null,
    };
  },
  computed: {
  },
  async created() {
    await this.fetchEvent();
  },
  methods: {
    ...mapActions("events", ["find"]),
    async fetchEvent() {
      try {
        const response = await this.find({ query: { } });
        this.events = response.data;
        console.log("Datos del evento:", response.data);
      } catch (error) {
        console.error("Error fetching event:", error);
      }
    }
  }
};
</script>
